import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Link from 'next/link';

const StyledWelcome = styled.div`
  position: relative;
  width: 100%;
  height: 33vh;
  background: ${props => props.theme.color.white};
  padding: 2rem;
  transform: translateY(50%);
  ${props => props.theme.center.standard};
`;

const Heading = styled.h1`
  color: ${props => props.theme.color.brand};
  font-family: ${props => props.theme.font.heading};
  font-size: ${props => props.theme.modularScale.large};
  text-align: center;
  padding: 0 0 2rem 0;
`;

const StyledLink = styled.a`
  background: ${props => props.theme.color.brand};
  color: ${props => props.theme.color.white};
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  font-family: ${props => props.theme.font.caption};
  font-size: ${props => props.theme.modularScale.small};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
`;

class Welcome extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
      <StyledWelcome>
        <Heading data-test-id="welcome-heading">Welcome, {this.props.userToken.user.username}</Heading>
        <Link prefetch href="/list/page">
          <StyledLink data-test-id="click-here-to-enter">Click here to enter your workspace</StyledLink>
        </Link>
      </StyledWelcome>
    )
  }
}

const mapStateToProps = state => ({
  userToken: state.auth.userToken
});

const mapDispatchToProps = props => () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
