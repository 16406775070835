import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import decode from 'jwt-decode';

import SignIn from '../SignIn/index';
import { signOut } from '../../store/auth/action';

class AuthGate extends Component {
  constructor(props) {
    // authGate needs to check that the userToken is correct on both the client and the server.
    // Before users reach this point, the cookie should also be set on the server.
    super(props);
  }

  componentWillMount() {
    if (this.props.userToken.jwt !== undefined && this.props.userToken.jwt.length > 0) {
      const {exp} = decode(this.props.userToken.jwt)
      // JWT is expired
      if (Date.now() >= exp * 1000) {
        global.document.cookie = 'jwt=; Max-Age=0; path=/;';
        signOut()
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.userToken.jwt !== undefined
        && this.props.userToken.jwt.length > 0 ? this.props.component : <SignIn />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userToken: state.auth.userToken
});

const mapDispatchToProps = props => () => ({
  signOut: userToken => dispatch(signOut(userToken))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthGate);
