import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Router from "next/router";
import { connect } from "react-redux";
import styled from "styled-components";
import AuthApi from "../../utils/api/AuthApi";
import Input from "../global/Forms/input";
import Button from "../global/Forms/button";

import {
  signIn,
  emailUpdate,
  passUpdate,
  passBoolean,
} from "../../store/auth/action";

const StyledForm = styled.form`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    width: 50%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    width: 40%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.large}) {
    width: 30%;
  }
`;

const StyledFieldset = styled.fieldset`
  position: absolute;
  min-height: 100vh;
  height: auto;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 2rem;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.white};

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    min-height: auto;
  }
`;

const StyledVector = styled("svg")`
  fill: ${(props) => props.theme.color.brand};
`;

const StyledPath = styled("path")`
  fill: ${(props) => props.theme.color.brand};
`;

const Heading = styled("h1")`
  color: ${(props) => props.theme.color.brand};
  font-family: ${(props) => props.theme.font.serif};
  text-align: center;
`;

const Legend = styled.legend`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  :not(:last-of-type) {
    margin-bottom: 1rem;
  }

  :last-of-type {
    margin-bottom: 2rem;
  }

  > label {
    flex: 0 0 25%;
    line-height: initial;
    max-width: 25%;
    text-align: left;
    padding: 0 1rem 0 0;
  }

  > input:not([type="checkbox"]) {
    background: ${(props) => props.theme.color.brandNeutral};
    width: 100%;
  }
`;

const Errors = styled.ul`
  margin-bottom: 2rem;

  > li {
    font-family: ${({ theme }) => theme.font.caption};
    font-size: ${({ theme }) => theme.modularScale.small};
    background-color: ${({ theme }) => theme.color.brandError};
    color: ${({ theme }) => theme.color.white};
    padding: 0.5rem 1rem;
  }
`;

const ShowHidePassword = styled(Input)`
  border: 0px none;
  position: relative;
  margin: 0;

  &:after {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const CenterAnchor = styled.a`
  display: block;
  margin-top: 1rem;
  text-align: center;
`;

const SignIn = (props) => {
  const [errors, setErrors] = useState([]);
  const [form, setState] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (errors.length > 0) setErrors([]);
  }, [form]);

  const updateField = (e) => {
    setState({ ...form, [e.target.name]: e.target.value });
  };

  const handleShowPass = (e) => {
    props.passBoolean(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const registerResponse = await AuthApi.login(
        process.env.NEXT_PUBLIC_STRAPI_HOST,
        {
          identifier: form.email,
          password: form.password,
        }
      );

      const { jwt } = registerResponse;

      if (jwt) {
        props.signIn(registerResponse);
        props.emailUpdate(form.email);
        props.passUpdate(form.password);
        document.cookie = `jwt=${jwt}; path=/;`;
        Router.push("/list/page");
      } else {
        const { data } = registerResponse;
        const message = data?.error?.message;
        throw { name: "ValidationError", message };
      }
    } catch (err) {
      setErrors([err]);
    }
  };

  if (props.userToken?.jwt) Router.push("/list/page");

  return (
    <StyledForm>
      <StyledFieldset data-test-id="sign-in-form">
        <div>
          <Legend>
            <StyledVector
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="28px"
              height="35px"
              viewBox="0 0 28 35"
              xmlSpace="preserve"
              title="Maisonette Logo"
              data-test-id="maisonette-logo"
            >
              <g>
                <StyledPath d="M20.4,1L14,14.1L7.6,1L0.5,15.9V33h27V15.9L20.4,1z M20.4,3.4l6,12.5h-12L20.4,3.4z M7.6,3.4l6.1,12.5h-12 L7.6,3.4z M13.5,32h-1.4v-7.8h1.4V32z M12.1,23.2c0-1,0.9-1.7,1.9-1.7c1,0,1.9,0.8,1.9,1.7H12.1z M15.9,32h-1.4v-7.8h1.4V32z M26.5,32H17v-8.8h0c0-1.5-1.3-2.8-2.9-2.8c-1.6,0-2.9,1.2-2.9,2.8h0V32H1.5V16.9h24.9V32z" />
                <StyledPath d="M9.3,19.1H3.3v8h5.9V19.1z M5.8,26H4.4v-5.9h1.4V26z M8.2,26H6.8v-5.9h1.4V26z" />
                <StyledPath d="M24.7,19.1h-5.9v8h5.9V19.1z M21.2,26h-1.4v-5.9h1.4V26z M23.7,26h-1.4v-5.9h1.4V26z" />
              </g>
            </StyledVector>
            <Heading data-test-id="heading-sign-in">Sign into Maisonette CMS</Heading>
          </Legend>

          {errors?.length > 0 && (
            <Errors data-test-id="error-sign-in">
              {errors.map(({ name, message }) => (
                <li key={name}>{message}</li>
              ))}
            </Errors>
          )}

          <InputWrapper>
            <Input
              id="email"
              name="email"
              type="email"
              data-test-id="email"
              value={form.email}
              changed={updateField}
              text="Email Address"
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              id="pass"
              name="password"
              type={props.showPassword ? "text" : "password"}
              data-test-id="password"
              value={form.password}
              changed={updateField}
              text="Password"
            />
          </InputWrapper>

          <InputWrapper>
            <ShowHidePassword
              id="passBoolean"
              type="checkbox"
              data-test-id="show-hide-password"
              defaultChecked={props.showPassword}
              changed={handleShowPass}
              text="Show/Hide Password"
            />
          </InputWrapper>

          <Button onClick={handleSubmit} type="submit" data-test-id="submit-sign-in">
            Submit
          </Button>
        </div>

        <Link href="/register" passHref>
          <CenterAnchor data-test-id="register-link">Register</CenterAnchor>
        </Link>
      </StyledFieldset>
    </StyledForm>
  );
};

SignIn.defaultProps = {
  userToken: {},
  showPassword: false,
  signIn: () => {},
  emailUpdate: () => {},
  passUpdate: () => {},
  userUpdate: () => {},
  passBoolean: () => {},
};

SignIn.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showPassword: PropTypes.bool,
  signIn: PropTypes.func,
  emailUpdate: PropTypes.func,
  passUpdate: PropTypes.func,
  userUpdate: PropTypes.func,
  passBoolean: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userToken: state.auth.userToken,
  showPassword: state.auth.showPassword,
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (userToken) => dispatch(signIn(userToken)),
  emailUpdate: (email) => dispatch(emailUpdate(email)),
  passUpdate: (pass) => dispatch(passUpdate(pass)),
  userUpdate: (user) => dispatch(userUpdate(user)),
  passBoolean: (showPassword) => dispatch(passBoolean(showPassword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
