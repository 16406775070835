import React from 'react';
import AuthGate from '../global/AuthGate';
import Welcome from '../Welcome/index';

const UserLogic = () => (
  <React.Fragment>
    <AuthGate component={<Welcome />} />
  </React.Fragment>
);

export default UserLogic;
