import React from 'react';
import { connect } from 'react-redux';
import Index from '../components/Index/index';
import { signIn } from '../store/auth/action';

const Home = () => (
  <React.Fragment>
    <Index />
  </React.Fragment>
);

Home.getInitialProps = ({ store, isServer }) => {
  const auth = store.getState().auth;
  store.dispatch(signIn(auth));
  return { isServer };
};

export default connect()(Home);
